<template>
  <div class="py-4" style="background: linear-gradient(270deg, #9D4EFF 0%, #782AF4 100%);">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
          <div class="d-md-flex align-items-center justify-content-between text-white">
            <div class="d-md-flex align-items-center text-lg-start text-center ">
              <div class="mt-3 mt-md-0">
                <h1 class="mb-0 fw-bold me-3 text-white">{{ task.name }}</h1>
              </div>
            </div>
            <div class="mt-3 mt-lg-0 text-lg-start text-center">
              <div v-if="tab === 'main'" class="float-end">
                <router-link :to="{name: 'MyAgency', query: {tab: 'task'}}" class="btn btn-info text-nowrap">
                  Danh sách công việc
                </router-link>&nbsp;
                <button data-bs-toggle="modal" data-bs-target="#updateTaskModal"
                        class="btn btn-light text-nowrap"><i class="fe fe-edit-2"></i> Sửa công việc
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white shadow-sm">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
          <ul class="nav nav-lt-tab" id="pills-tab" role="tablist">
            <li class="nav-item ms-0" role="presentation">
              <a v-on:click="setTab('main')" :class="tab === 'main' ? 'nav-link active' : 'nav-link'"
                 id="pills-main-tab" data-bs-toggle="pill" href="#pills-main" role="tab"
                 aria-controls="pills-main" aria-selected="false">Tổng quan</a>
            </li>
            <li class="nav-item ms-0" role="presentation">
              <a v-on:click="setTab('comments')" :class="tab === 'comments' ? 'nav-link active' : 'nav-link'"
                 id="pills-comments-tab" data-bs-toggle="pill" href="#pills-comments" role="tab"
                 aria-controls="pills-comments" aria-selected="false">Ghi chú ({{ comments.paginatorInfo.total }})</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="tab-content" id="pills-tabContent">
            <div :class="tab === 'main' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-main"
                 role="tabpanel" aria-labelledby="pills-about-tab">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="card ">
                    <div class="card-body">
                      <table class="table mb-4 text-nowrap list">
                        <thead class="table-light">
                        <tr>
                          <th scope="col">Tên công việc</th>
                          <th scope="col">Trạng thái</th>
                          <th scope="col">Phòng tiếp nhận</th>
                          <th scope="col">Người xử lý</th>
                          <th scope="col">Người theo dõi</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="align-middle">
                            {{ task.name }}
                          </td>
                          <td class="align-middle">
                            <a href="#" v-on:click="setCurrentTaskStatus(task); setCurrentTask(task)" data-bs-toggle="modal" data-bs-target="#changeTaskStatusModal">
                              <task-status :status="task.status" />
                            </a>
                          </td>
                          <td class="align-middle">
                            <a href="#" v-on:click="setCurrentTaskDepartment(task); setCurrentTask(task)" data-bs-toggle="modal" data-bs-target="#changeTaskDepartmentModal">
                              <task-department-badge :department="task.department ? task.department.name : ''"/>
                            </a>
                          </td>
                          <td class="align-middle">
                            <a href="#" v-on:click="setCurrentTaskAssignee(task); setCurrentTask(task)" data-bs-toggle="modal" data-bs-target="#changeTaskAssigneeModal">
                              <task-assignee :assignee="task.assignee ? task.assignee.name: 'Chưa ai tiếp nhận'"/>
                            </a>
                          </td>
                          <td class="align-middle">
                            <div v-for="(watcher, index) in task.watchers.data" :key="'watcher' + index">
                              <task-assignee :assignee="watcher.name"/>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <div class="row">
                        <div class="col-md-8">
                          <div class="p-3">
                            {{ task.content }}
                          </div>
                          <ul class="list-group list-group-flush list-timeline-activity mt-3">
                            <li class="list-group-item px-0 pt-0 border-0 mb-2" :key="'log' + log.id" v-for="log in taskLogs.data">
                              <div class="row">
                                <div class="col-auto">
                                  <div class="avatar avatar-md avatar-indicators avatar-online">
                                    <img alt="avatar" :src="log.employee && log.employee.thumbnail ? log.employee.thumbnail.link : '/assets/images/avatar/avatar.jpg'" class="rounded-circle">
                                  </div>
                                </div>
                                <div class="col ms-n2">
                                  <h4 class="mb-0 h5">{{ log.employee.name }}</h4>
                                  <p class="mb-1">{{ log.content }}</p>
                                  <span class="fs-6">{{ timeago(log.created_at) }}</span>
                                </div>
                              </div>
                            </li>
                            <v-pagination
                                v-if="taskLogs.paginatorInfo.lastPage > 1"
                                v-model="taskLogs.paginatorInfo.currentPage"
                                :pages="taskLogs.paginatorInfo.lastPage"
                                :range-size="1"
                                active-color="#DCEDFF"
                                @update:modelValue="loadTaskLogs"
                            />
                          </ul>
                        </div>
                        <div class="col-md-4">
                          <form v-on:submit.prevent="addComment">
                            <div class="alert alert-danger" role="alert" v-if="addCommentMessage">
                              {{ addCommentMessage }}
                            </div>
                            <textarea v-model="comment" class="form-control"></textarea>
                            <button type="submit" class="btn btn-primary mt-3">Thêm ghi chú</button>
                          </form>
                          <table class="table table-bordered mt-3">
                            <tbody>
                            <tr v-for="(item, index) in customFields" :key="'customFieldsTR' + index">
                              <td>{{ item.key }}</td>
                              <td>{{ getValueByMetaKey(item.key, task.metaData.data) }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :class="tab === 'comments' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-comments"
                 role="tabpanel" aria-labelledby="pills-comments-tab">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="d-flex w-lg-40 mb-4" :key="'comment' + comment.id" v-for="comment in comments.data">
                    <img alt="avatar" :src="comment.employee && comment.employee.thumbnail ? comment.employee.thumbnail.link : '/assets/images/avatar/avatar.jpg'" class="rounded-circle avatar-md">
                    <div class=" ms-3">
                      <small>{{ comment.employee.name }}, {{ timeago(comment.created_at) }}</small>
                      <div class="d-flex">
                        <div class="card mt-2 rounded-top-md-left-0">
                          <div class="card-body p-3">
                            <p class="mb-0 text-dark">
                              {{ comment.to }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <v-pagination
                      v-if="comments.paginatorInfo.lastPage > 1"
                      v-model="comments.paginatorInfo.currentPage"
                      :pages="comments.paginatorInfo.lastPage"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="loadComments"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="changeTaskStatusModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <form v-on:submit.prevent="changeTaskStatus" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Thay đổi trạng thái công việc
            </h4>
            <button type="button" id="changeTaskStatusModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="changeStatusTaskMessage !== ''">
                    {{ changeStatusTaskMessage }}
                  </div>
                  <div class="col-md-12">
                    <label class="form-label">Trạng thái</label>
                    <task-status-select v-model:status="taskStatus" unique="changeStatusTask"/>
                    <label class="form-label mt-3" for="changeStatusTaskComment">Ghi chú</label>
                    <textarea class="form-control" v-model="changeTaskStatusComment" id="changeStatusTaskComment"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Thay đổi trạng thái</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <button type="button" class="d-none" id="changeStatusTaskModalOpen" data-bs-toggle="modal" data-bs-target="#addEmployeeModal"></button>

  <div class="modal fade" id="changeTaskDepartmentModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <form v-on:submit.prevent="changeTaskDepartment" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Chuyển giao công việc
            </h4>
            <button type="button" id="changeTaskDepartmentModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="changeTaskDepartmentMessage !== ''">
                    {{ changeTaskDepartmentMessage }}
                  </div>
                  <div class="col-md-12">
                    <label class="form-label">Phòng muốn chuyển giao</label>
                    <task-department-select :departments="agency && agency.departments ? agency.departments.data : []" v-model:code="changeTaskDepartmentDepartmentCode" unique="changeTaskDepartment"/>
                    <label class="form-label mt-3" for="changeTaskDepartmentComment">Ghi chú</label>
                    <textarea class="form-control" v-model="changeTaskDepartmentComment" id="changeTaskDepartmentComment"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Chuyển giao công việc</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="changeTaskAssigneeModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <form v-on:submit.prevent="changeTaskAssignee" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Gán công việc
            </h4>
            <button type="button" id="changeTaskAssigneeModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="changeTaskAssigneeMessage !== ''">
                    {{ changeTaskAssigneeMessage }}
                  </div>
                  <div class="col-md-12">
                    <label class="form-label">Chọn người được gán</label>
                    <task-assignee-select :departments="agency && agency.departments && agency.departments.data ? agency.departments.data : []" field="uid" v-model:change="changeTaskAssigneeUid" unique="changeTaskAssignee"/>
                    <label class="form-label mt-3" for="changeTaskAssigneeComment">Ghi chú</label>
                    <textarea class="form-control" v-model="changeTaskAssigneeComment" id="changeTaskAssigneeComment"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Gán công việc</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" id="updateTaskModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <form v-on:submit.prevent="updateTask" class="w-100">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Sửa công việc
            </h4>
            <button type="button" id="updateTaskModalClose" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="row g-3">
                  <div class="alert alert-danger" role="alert" v-if="updateTaskMessage !== ''">
                    {{ updateTaskMessage }}
                  </div>
                  <div class="col-md-6">
                    <label for="updateTaskName" class="form-label">Tên công việc</label>
                    <input v-model="currentTask.name" id="updateTaskName" class="form-control" type="text" required/>
                  </div>
                  <div class="col-md-6">
                    <label for="updateTaskContent" class="form-label">Mô tả</label>
                    <textarea class="form-control" id="updateTaskContent" v-model="currentTask.content"></textarea>
                  </div>
                  <div class="col-md-6" v-for="(item, index) in customFields" :key="'field' + index">
                    <label :for="'updateTask' + index" class="form-label">{{ item.key }}</label>
                    <input v-model="item.value" :id="'updateTask' + index" class="form-control" type="text"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="btn btn-primary" type="submit">Cập nhật</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <Modal id="messageModal" :type="modalType" :message="modalMessage" :title="modalTitle"
         :message-detail="modalMessageDetail"/>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Modal from "@/components/Modal";
import TaskStatus from "@/components/admin/agency/taskStatus";
import TaskDepartmentBadge from "@/components/admin/agency/taskDepartmentBadge";
import TaskAssignee from "@/components/admin/agency/taskAssignee";
import TaskStatusSelect from "@/components/admin/agency/taskStatusSelect";
import TaskDepartmentSelect from "@/components/admin/agency/taskDepartmentSelect";
import TaskAssigneeSelect from "@/components/admin/agency/taskAssigneeSelect";
import {closeModal, openModal, timeago} from "@/core/services/utils.service";

export default {
  name: "AdminTaskDetail",
  components: {
    Modal,
    VPagination,
    TaskStatus,
    TaskDepartmentBadge,
    TaskAssignee,
    TaskStatusSelect,
    TaskDepartmentSelect,
    TaskAssigneeSelect,
  },
  data() {
    return {
      code: "",
      task: {
        code: "",
        name: "",
        email: "",
        phone: "",
        taskLogs: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            total: 0,
            lastPage: 0
          }
        },
        metaData: {
          data: []
        },
        watchers: {
          data: []
        }
      },
      agency: null,
      settings: {
        _custom_fields: {}
      },
      customFields: [],
      taskLogs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          total: 0,
          lastPage: 0
        }
      },
      comments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          total: 0,
          lastPage: 0
        }
      },
      comment: "",
      addCommentMessage: "",
      changeStatusTaskMessage: "",
      changeTaskStatusComment: "",
      changeTaskDepartmentMessage: "",
      changeTaskDepartmentComment: "",
      changeTaskDepartmentDepartmentCode: "",
      changeTaskAssigneeMessage: "",
      changeTaskAssigneeComment: "",
      changeTaskAssigneeUid: "",
      currentTask: {
        name: ""
      },
      taskStatus: "",
      currentEmployee: {
        name: ""
      },
      currentDepartment: {
        name: ""
      },
      modalMessage: "",
      modalType: "",
      modalMessageDetail: "",
      modalTitle: "",
      departments: [],
      tab: "main",
      updateTaskMessage: ""
    }
  },
  methods: {
    loadTask() {
      let query = `query($code: String!) {
        task(code: $code) {
          id
          code
          name
          content
          status
          department {
            code
            name
          }
          assignee {
            uid
            name
          }
          metaData(first: 100) {
            data {
              meta_key
              meta_value
            }
          }
          watchers(first: 100) {
            data {
              uid
              name
            }
          }
        }
      }`;
      ApiService.graphql(query, {code: this.code})
          .then(({data}) => {
            if (data.data.task) {
              this.task = data.data.task;
              this.currentTask = JSON.parse(JSON.stringify(this.task));
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadTaskLogs() {
      let query = `query($code: String!, $page: Int) {
        task(code: $code) {
          taskLogs(first:5, page: $page, orderBy: [{column: "id", order: DESC}]) {
            data {
              from
              to
              content
              created_at
              employee {
                name
                uid
                thumbnail {
                  link
                }
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;
      ApiService.graphql(query, {code: this.code, page: this.taskLogs.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.task && data.data.task.taskLogs) {
              this.taskLogs = data.data.task.taskLogs;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadAgency() {
      let query = `query($code: String!) {
        task(code: $code) {
          agency {
            metaData(first: 10, where: {AND: [{column: "meta_key", value:["_custom_fields"], operator: IN}]}) {
              data {
                meta_key
                meta_value
              }
            }
            departments(first: 100) {
              data {
                id
                code
                name
                employees(first: 100) {
                  data {
                    id
                    uid
                    name
                  }
                }
              }
            }
          }
        }
      }`;
      ApiService.graphql(query, {code: this.code})
          .then(({data}) => {
            if (data.data && data.data.task && data.data.task.agency) {
              this.agency = data.data.task.agency;
              if (this.agency.metaData && this.agency.metaData.data) {
                for (let i = 0; i < this.agency.metaData.data.length; i++) {
                  this.settings[this.agency.metaData.data[i].meta_key].meta_value = this.agency.metaData.data[i].meta_value;
                }
              }
              if (this.settings._custom_fields && this.settings._custom_fields.meta_value) {
                let keys = this.settings._custom_fields.meta_value.split("\n");
                for (let i = 0; i < keys.length; i++) {
                  this.customFields[i] = {
                    key: keys[i],
                    value: ""
                  }
                }
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadComments() {
      let query = `query($code: String!, $page: Int) {
        task(code: $code) {
          taskLogs(first:5, page: $page, orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "action", value: "comment"}]}) {
            data {
              to
              created_at
              employee {
                name
                uid
                thumbnail {
                  link
                }
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }`;
      ApiService.graphql(query, {code: this.code, page: this.comments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.task && data.data.task.taskLogs) {
              this.comments = data.data.task.taskLogs;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    addComment() {
      this.addCommentMessage = "";

      let query = `mutation($task_code: String!, $comment: String!) {
        addTaskComment(task_code: $task_code, comment: $comment)
      }`;

      ApiService.graphql(query, {task_code: this.task.code, comment: this.comment})
          .then(({data}) => {
            if (data.data && data.data.addTaskComment) {
              this.comment = "";
              this.loadTaskLogs();
              this.loadComments();
            } else {
              this.addCommentMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.addCommentMessage = response.message;
          });
    },
    changeTaskStatus() {
      this.modalTitle = "Thay đổi trạng thái công việc";

      let query = `mutation($task_code: String!, $status: String!, $comment: String) {
        changeTaskStatus(task_code: $task_code, status: $status, comment: $comment)
      }`;

      ApiService.graphql(query, {task_code: this.currentTask.code, status: this.taskStatus, comment: this.changeTaskStatusComment})
          .then(({data}) => {
            if (data.data && data.data.changeTaskStatus) {
              this.modalMessage = "Thay đổi trạng thái công việc thành công";
              this.modalType = "success";
              closeModal("changeTaskStatusModal");
              openModal("messageModal");
              this.loadTask();
              this.loadTaskLogs();
              this.changeTaskStatusComment = "";
            } else {
              this.changeStatusTaskMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.changeStatusTaskMessage = response.message;
          });
    },
    changeTaskDepartment() {
      this.modalTitle = "Chuyển giao công việc";

      let query = `mutation($task_code: String!, $department_code: String!, $comment: String) {
        changeTaskDepartment(task_code: $task_code, department_code: $department_code, comment: $comment)
      }`;

      ApiService.graphql(query, {task_code: this.currentTask.code, department_code: this.changeTaskDepartmentDepartmentCode, comment: this.changeTaskDepartmentComment})
          .then(({data}) => {
            if (data.data && data.data.changeTaskDepartment) {
              this.modalMessage = "Chuyển giao công việc thành công";
              this.modalType = "success";
              closeModal("changeTaskDepartmentModal");
              openModal("messageModal");
              this.loadTask();
              this.loadTaskLogs();
              this.changeTaskDepartmentComment = "";
            } else {
              this.changeTaskDepartmentMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.changeTaskDepartmentMessage = response.message;
          });
    },
    changeTaskAssignee() {
      this.modalTitle = "Gán công việc";

      let query = `mutation($task_code: String!, $uid: String!, $comment: String) {
        changeTaskAssignee(task_code: $task_code, uid: $uid, comment: $comment)
      }`;

      ApiService.graphql(query, {task_code: this.currentTask.code, uid: this.changeTaskAssigneeUid, comment: this.changeTaskAssigneeComment})
          .then(({data}) => {
            if (data.data && data.data.changeTaskAssignee) {
              this.modalMessage = "Gán công việc thành công";
              this.modalType = "success";
              closeModal("changeTaskAssigneeModal");
              openModal("messageModal");
              this.loadTask();
              this.loadTaskLogs();
              this.changeTaskAssigneeComment = "";
            } else {
              this.changeTaskAssigneeMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.changeTaskAssigneeMessage = response.message;
          });
    },
    setCurrentTask(task) {
      this.currentTask = task;
    },
    setCurrentTaskStatus(task) {
      this.taskStatus = task.status;
    },
    setCurrentTaskDepartment(task) {
      this.changeTaskDepartmentDepartmentCode = task.department.code;
    },
    setCurrentTaskAssignee(task) {
      this.changeTaskAssigneeUid = task.assignee ? task.assignee.uid : "";
    },
    setCurrentDepartment(department) {
      this.currentDepartment = department;
    },
    processTab() {
      let tab = this.$route.query.tab;
      if (tab) {
        this.tab = tab;
      }
    },
    setTab(tab) {
      this.tab = tab;
    },
    timeago(x) {
      return timeago(x);
    },
    getValueByMetaKey(key, metaData) {
      if (metaData && metaData.length) {
        for (let i = 0; i < metaData.length; i++) {
          if (key === metaData[i].meta_key) {
            return metaData[i].meta_value;
          }
        }
      }

      return "";
    },
    updateTask() {
      let query = `mutation($task_code: String!, $name: String, $content: String, $customFields: Mixed) {
        updateTask(task_code: $task_code, name: $name, content: $content, custom_fields: $customFields) {
            id
        }
      }`;

      ApiService.graphql(query, {task_code: this.currentTask.code, name: this.currentTask.name, content: this.currentTask.content, customFields: this.customFields})
          .then(({data}) => {
            if (data.data && data.data.updateTask) {
              closeModal("updateTaskModal");
              this.loadTask();
            } else {
              this.updateTaskMessage = data.errors[0].message;
            }
          })
          .catch((response) => {
            this.updateTaskMessage = response.message;
          });
    },
    reRender() {
      this.loadTask();
      this.loadAgency();
      this.loadTaskLogs();
      this.loadComments();
    }
  },
  mounted() {
    this.code = this.$route.params.code;
    this.processTab();
    this.reRender();
  },
  watch: {
    "task.metaData.data": {
      handler(newVal) {
        for (let i = 0; i < this.customFields.length; i++) {
          this.customFields[i].value = this.getValueByMetaKey(this.customFields[i].key, newVal);
        }
      },
      deep: true
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.code = to.params.code;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  }
}
</script>
